<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-01-30 09:50:07
 * @LastEditTime: 2021-02-06 09:57:12
 * @LastEditors: fely
-->
<template>
  <div class="wrapper">
    <div class="header">
      <top-header></top-header>
      <div class="text-con">
        <h1>亿慧小秘书</h1>
        <span>www.gjyxms.com</span>
      </div>
    </div>
    <div class="content" style="position: relative;">
      <div class="intro">
        <div class="intro-wrap">
          <div class="left-intro">
            <div class="one">
              <span class="text">ABOUT</span>
              <span class="quot">”</span>
            </div>
            <div class="two">
              <span>PRODUCTS</span>
              <div class="underline"></div>
            </div>
            <div class="three">
              <span>产</span>
              <div class="seprate"></div>
              <span>品</span>
              <div class="seprate"></div>
              <span>介</span>
              <div class="seprate"></div>
              <span>绍</span>
            </div>
            <div class="four">
              <span>支持Android和IOS</span>
              <div id="downUrl" @click="download">下载</div>
            </div>
          </div>
          <div class="right-intro">
            亿慧小秘书app是一款与求职招聘相关的手机软件，在这里招聘双方可以通过在线聊天的方式轻松的找到中意的人才或中意的岗位。此外在亿慧小秘书app上还可以查看工资条。企业通过小秘书工资条网站发送电子工资
            条给员工，员工下载亿慧小秘书app后可以便捷的在亿慧小秘书app端查看。
          </div>
        </div>
        
      </div>
      <div class="footer" style="position: absolute;bottom: 0;">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
    <div id="shiled-wrapper" v-show="show">
      
      <div id="download">
        <span id="close" @click="close">×</span>
        <img src="~@/assets/images/download.png" style="height: 300px; width: 300px;">
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
export default {
  name: '',
  data() {
    return {
      show:false
    }
  },
  components: {
    TopHeader
  },
  methods: {
    download(){
      this.show = true
    },
    close(){
      this.show = false
    }
  },
}
</script>

<style scoped lang="less">
@import "../styles/public.css";
.wrapper{
  width: 100%;
  height: 100%;
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex;
  flex-direction: column;
}
.content{
  width: 100%;
  flex: 1;
  position: relative;
  .intro{
    margin-top: 64px;
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    justify-content: center;
    .intro-wrap{
      width: 980px;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .left-intro{
      .one{
        display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
        display: -moz-box; /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox; /* 混合版本语法: IE 10 */
        display: -webkit-flex; /* 新版本语法: Chrome 21+ */
        display: flex;
        span.text{
          color: #333;
          font-size: 40px;
          // line-height: 58px;
          font-weight: 700;
        }
        span.quot{
          display: inline-block;
          margin-left: 90px;
          color: #F5F7F9;
          font-size: 140px;
          font-weight: 500;
          height: 45px;
          line-height: 113px;
        }
      }
      .two{
        color: #333;
        font-size: 40px;
        font-weight: 700;
        margin-top: 10px;
        position: relative;
        text-align: left;
        span{
          line-height: 58px;
        }
        .underline{
          position: absolute;
          left: 3px;
          right: 0;
          bottom: 15px;
          z-index: -1;
          width: 220px;
          height: 10px;
          background: rgba(0, 122, 241, 0.8);
        }
      }
      .three{
        display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex;
        align-items: center;
        font-size: 16px;
        color: #333;
        .seprate{
          margin: 0 5px;
          width: 63px;
          height: 2px;
          background-color: #DBD9DC;
        }
      }
      .four{
        margin-top: 24px;
        font-size: 16px;
        line-height: 28px;
        color: #939295;
        display: flex;
        align-items: center;
        #downUrl{
          margin-left: 25px;
        }
      }
    }
    .right-intro{
      width: 400px;
      height: 200px;
      text-align: left;
      color: #939295;
      font-size: 16px;
      line-height: 28px;
    }
  }
}
#downUrl{
  color: #4E90FF;
  cursor: pointer;
}
#download{
  position: relative;
  span{
    cursor: pointer;
    color: #333;
    position: absolute;
    left: 285px;
    top: -15px;
    width: 30px;
    height: 30px;
    background: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#shiled-wrapper{
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
</style>
